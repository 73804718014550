// eslint-disable-next-line import/no-duplicates
import {format as dateFormat, Locale} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import {nl, de, enUS} from 'date-fns/esm/locale';

type Opts = Parameters<typeof dateFormat>;

export const useLocaleDate = () => {
  const {locale: currentLocale} = useI18n();
  const localeMapping = {nl, de, en: enUS};

  // @ts-ignore known bug (can't resolve nested type), should be fixed in ts 4.9.
  const locale = computed<Locale>(() => localeMapping[currentLocale.value] ?? localeMapping.en);

  return (date: Opts[0], format: Opts[1] = 'dd MMMM yyyy', opts?: Opts[2]) =>
    dateFormat(date, format, {locale: locale.value, ...opts});
};
